<template>
  <div class="mt-12 pt-10">
    <section class="slack py-10 text-primary">
      <div class="slack__title">
        <img src="@/assets/img/slack.png" alt="slack" class="slack_img" />
        <h2>+ VibrantCreator</h2>
      </div>

      <h1 class="slack__heading">Introducing Vibrantcreator for Slack.</h1>
      <div class="slack__description">
        Subscribe to VibrantCreator notifications on Slack in a smart way that
        will save you a lot of troubles.
      </div>

      <div class="slack__button">
        <c-pseudo-box
          as="a"
          href="https://slack.com/oauth/authorize?client_id=514553026068.2928715089363&scope=incoming-webhook"
          py="6"
          px="3"
          :_hover="{
            textDecoration: 'none',
            cursor: 'pointer',
          }"
        >
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </c-pseudo-box>
      </div>

      <div class="slack__steps steps_bg1">
        <h1>How to get VibrantCreator on your Slack</h1>
        <p class="mt-2">
          Click on the Add to Slack button. You will be redirected to a Slack
          Page
        </p>
        <br />
        <img src="@/assets/img/step4.png" alt="slack" width="600px" />
      </div>

      <div class="slack__steps steps_bg2">
        <h1 class="text-2xl font-bold">Select workspace and channel</h1>
        <p class="mt-2">
          You will prompted to select the company in VibrantCreator you want to
          subscribe to.<br />
          Select the desired workspace and channel. Click on allow to add
          VibrantCreator Notifications
        </p>

        <div class="slack__button">
          <c-pseudo-box
            as="a"
            href="https://slack.com/oauth/authorize?client_id=514553026068.2928715089363&scope=incoming-webhook"
            py="6"
            px="3"
            :_hover="{
              textDecoration: 'none',
              cursor: 'pointer',
            }"
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </c-pseudo-box>
        </div>
      </div>

      <div class="slack__steps">
        <br />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { registerSlack } from '../../../services/slack';
export default {
  data() {
    return {
      steps: [
        'Click on the Add to Slack button',
        'You will be redirected to a Slack Page ',
        'You will prompted to select the company in VibrantCreator you want to subscribe to.',
        'Select the company and the desired channel ',
        'Finally click on "Allow" ',
      ],
    };
  },
  created() {
    if (this.$route.query.code) {
      this.addSlack();
    } else if (this.$route.query.error)
      this.$toast({
        title: 'Danger.',
        description: `Sorry VibrantCreator notifications not added  `,
        status: 'error',
        position: 'top',
        duration: 3000,
      });
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
  },
  methods: {
    async addSlack() {
      try {
        await registerSlack(this.activeCompany.id, {
          code: this.$route.query.code,
        });
        this.$toast({
          title: 'Success.',
          description: `VibrantCreator notifications added successfully.`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        // this.$router.push({ path: '/app' });
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'Danger.',
          description: `Sorry VibrantCreator notifications not added `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slack {
  text-align: center;
  margin-top: 50px;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      margin-right: 5px;
    }

    h2 {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.25rem;
      color: #f05222;
    }
  }

  &__heading {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 10px;
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 30px;

    h1 {
      font-weight: 700;
      font-size: 1.25rem;
    }
  }
  .steps_bg1 {
    background-color: #fe9073;
    margin-top: 30px;
  }
  .steps_bg2 {
    background-color: #0c2b47;
    color: white;
  }
}
</style>
