var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-12 pt-10"},[_c('section',{staticClass:"slack py-10 text-primary"},[_vm._m(0),_c('h1',{staticClass:"slack__heading"},[_vm._v("Introducing Vibrantcreator for Slack.")]),_c('div',{staticClass:"slack__description"},[_vm._v(" Subscribe to VibrantCreator notifications on Slack in a smart way that will save you a lot of troubles. ")]),_c('div',{staticClass:"slack__button"},[_c('c-pseudo-box',{attrs:{"as":"a","href":"https://slack.com/oauth/authorize?client_id=514553026068.2928715089363&scope=incoming-webhook","py":"6","px":"3","_hover":{
          textDecoration: 'none',
          cursor: 'pointer',
        }}},[_c('img',{attrs:{"alt":"Add to Slack","height":"40","width":"139","src":"https://platform.slack-edge.com/img/add_to_slack.png","srcSet":"https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"}})])],1),_vm._m(1),_c('div',{staticClass:"slack__steps steps_bg2"},[_c('h1',{staticClass:"text-2xl font-bold"},[_vm._v("Select workspace and channel")]),_vm._m(2),_c('div',{staticClass:"slack__button"},[_c('c-pseudo-box',{attrs:{"as":"a","href":"https://slack.com/oauth/authorize?client_id=514553026068.2928715089363&scope=incoming-webhook","py":"6","px":"3","_hover":{
            textDecoration: 'none',
            cursor: 'pointer',
          }}},[_c('img',{attrs:{"alt":"Add to Slack","height":"40","width":"139","src":"https://platform.slack-edge.com/img/add_to_slack.png","srcSet":"https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"}})])],1)]),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slack__title"},[_c('img',{staticClass:"slack_img",attrs:{"src":require("@/assets/img/slack.png"),"alt":"slack"}}),_c('h2',[_vm._v("+ VibrantCreator")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slack__steps steps_bg1"},[_c('h1',[_vm._v("How to get VibrantCreator on your Slack")]),_c('p',{staticClass:"mt-2"},[_vm._v(" Click on the Add to Slack button. You will be redirected to a Slack Page ")]),_c('br'),_c('img',{attrs:{"src":require("@/assets/img/step4.png"),"alt":"slack","width":"600px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-2"},[_vm._v(" You will prompted to select the company in VibrantCreator you want to subscribe to."),_c('br'),_vm._v(" Select the desired workspace and channel. Click on allow to add VibrantCreator Notifications ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slack__steps"},[_c('br')])
}]

export { render, staticRenderFns }